


















import { defineComponent, PropType } from '@nuxtjs/composition-api';

export type CardData = {
  id: number;
  title: string;
  media: {
    id: number;
    title: string;
    media_path: string;
  };
  url: string;
};

export default defineComponent({
  name: 'GalleryCard',
  props: {
    cardData: {
      type: Object as PropType<CardData>,
      required: true,
      default: () => ({
        id: 0,
        title: '',
        media: {
          id: 0,
          title: '',
          media_path: ''
        },
        url: ''
      })
    }
  }
});
