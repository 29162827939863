




















import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
import type { CardData } from '~/components/cms-components/category-highlights/GalleryCard.vue';

type Url = {
  id: number;
  title: string;
  url: string;
  target: string;
  media_path: string;
};

type GalleryData = {
  product_tags: CardData[];
  urls: Url[];
  custom_fields: Record<string, string>[];
};

export default defineComponent({
  name: 'Gallery',
  props: {
    data: {
      type: Object as PropType<GalleryData>,
      default: () => ({
        product_tags: [],
        urls: []
      })
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const galleryItems = computed(() => {
      let items = [];
      if (props.data?.product_tags?.length > 0) {
        items = props.data.product_tags;
      } else if (props.data?.urls?.length > 0) {
        items = props.data.urls.map((item) => ({
          id: item.id,
          title: item.title,
          url: item.url,
          target: item.target,
          media: { media_path: item.media_path }
        }));
      }

      const isSliderField = props.data.custom_fields?.find(
        (field) => field.key === 'is_slider'
      );
      if (!isSliderField) return items;
      if (isSliderField.value_select === 'true') return items;
      return items.slice(0, 6);
    });

    const settings = {
      perView: 6
    };

    return { isDesktop, galleryItems, settings };
  }
});
